.containerWrapper {
  margin: auto;
  margin-top: 200px;
  width: 1200px;
}

.description {
  line-height: 24px;
  font-size: 16px;
}

.linkExperiment {
  text-decoration: none;
  font-weight: 900;
  color: #f5a623;
}

.imageContainer {
  margin: 50px auto;
}

.projectImages {
  width: 100%;
}

.projectImageMyra {
  width: 360px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: calc(50% - 180px);
}

@media (max-width: 1280px) {
  .containerWrapper {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .containerWrapper {
    margin-top: 100px;
  }

  .projectImageMyra {
    width: 280px;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-left: calc(50% - 140px);
  }
}
