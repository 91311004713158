html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: Lato, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Lato", monospace;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,900&display=swap");
