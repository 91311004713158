.footer {
  border-top: 0.5px solid rgba(255, 255, 255, 0.3);
}

.footerWrapper {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 0;
}

.container1 {
  flex: 1;
  padding: 100px 40px;
  color: white;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 700;
  border-right: 0.5px solid rgba(255, 255, 255, 0.3);
}

.container2 {
  flex: 1;
  font-size: 16px;
  line-height: 28px;
  padding: 100px 40px;
  color: rgba(255, 255, 255, 0.4);
}

.social {
  color: #f5a623;
  text-decoration: none;
}

.contactMe {
  margin-top: 50px;
}

.thanks {
  color: #f5a623;
  font-weight: 900;
  text-decoration: none;
}

.help {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1200px) {
  .footerWrapper {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .footerWrapper {
    flex-direction: column;
    width: 100%;
  }

  .container1 {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
    border-right: 0;
    padding: 20px 40px;
  }

  .contactMe {
    margin-top: 30px;
  }
  .container2 {
    padding: 20px 40px;
  }
}
