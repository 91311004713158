.navbar {
  width: 100vw;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 16px 0 16px 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  z-index: 100;
  position: fixed;
  top: 0;
}
.navWrapper {
  width: 1200px;
  display: flex;
  margin: auto 20px;
}

.navLinkLogo {
  text-decoration: none;
  color: #fff;
  display: flex;
}

.navLinkItems {
  text-decoration: none;
  color: #fff;
}

.logo {
  display: flex;
  text-align: left;
  height: 32px;
  margin: auto;
  cursor: pointer;
}

.logoText {
  /* font-family: "Montserrat-Regular"; */
  font-family: "Montserrat";
  margin-left: 10px;
}

.navLinks {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  text-align: left;
  font-size: 14px;
}

.linksWrapper {
  /* width: 100%; */
  display: flex;
}

.hamburger {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  margin: auto 40px;
}

.hamburgerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.line1,
.line2,
.line3 {
  width: 20px;
  height: 2px;
  background-color: #f5a623;
  margin: 2px auto;
  border-radius: 4px;
}

.link1,
.link2 {
  margin-right: 60px;
  cursor: pointer;
  padding-top: 16px;
}

/* .link2 {
  margin-right: 40px;
  cursor: pointer;
  padding-top: 16px;
} */

.link3 {
  cursor: pointer;
  padding-top: 16px;
  color: #fff;
  text-decoration: none;
}

.reduceOpacity {
  opacity: 0.4;
}

.close {
  display: none;
}

.openLinks {
  display: contents;
}

.open {
  height: 100vh;
  width: 100vh;
  z-index: 100;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  text-align: right;
}

@media (max-width: 1280px) {
  .navbar {
    width: 100%;
  }
  .navWrapper {
    width: 85%;
    display: flex;
  }
}

@media (max-width: 900px) {
  .link1,
  .link2 {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .logoText {
    display: none;
  }

  .link1,
  .link2 {
    display: none;
  }

  .link3 {
    padding-top: 0;
  }

  .aboutMe {
    margin: auto;
    padding-top: 8px;
  }

  .aboutMeText {
    display: none;
  }
}
