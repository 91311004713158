.intro {
  margin-top: 250px;
  margin-bottom: 10px;
  width: 100vw;
  display: flex;
}

.introWrapper {
  width: 1200px;
  /* background-color: cadetblue; */
  margin: auto;
  display: flex;
  position: relative;
  /* height: 600px; */
}

.leftRightWrapper {
  display: flex;
  margin: auto;
  width: 1200px;
}

.patternImage {
  position: absolute;
  top: -150px;
  z-index: -1;
  opacity: 0.8;
  /* margin-left: 8.5%; */
}

.right {
  display: flex;
  flex: 1;
  /* width: 50%; */
  height: 600px;
  margin: auto;
}

.left {
  text-align: left;
  flex: 1;
  /* width: 50%; */
  margin: auto;
  margin-right: 20px;
}
.hello {
  color: #f5a623;
  font-size: 22px;
  margin-bottom: 16px;
  font-family: "Montserrat";
  font-weight: 300;
}
.craft {
  font-size: 60px;
  /* font-family: "Montserrat-Black"; */
  /* font-family: "Montserrat"; */
  font-family: "Liu Jian Mao Cao";
  font-weight: 600;
  color: white;
  margin: 0;
}

.experience {
  font-size: 62px;
  font-family: "Liu Jian Mao Cao";
  /* font-family: "Montserrat"; */
  font-weight: 900;
  color: #f5a623;
  margin-top: 0;
  line-height: 60px;
}

.description {
  /* width: 490px; */
  /* font-family: "Montserrat-Regular"; */
  font-family: "Montserrat";
  font-weight: 300;
  color: white;
  margin: 32px 0;
  line-height: 30px;
  font-size: 18px;
}

.linkReachOut {
  text-decoration: none;
  color: #f5a623;
  font-weight: 800;
}

.leftImagesWrapper,
.rightImagesWrapper {
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  width: 280px;
  box-sizing: border-box;
  padding: 10px;
}

.leftTop,
.rightBottom,
.leftBottom,
.rightTop {
  width: 100%;
  background-color: #f5a623;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.leftTop,
.rightBottom {
  height: 65%;
}

.leftBottom,
.rightTop {
  height: 35%;
}

.introNavLink {
  width: 100%;
  height: 100%;
}

.introNavLink2 {
  /* width: 100%; */
  height: 100%;
}

.leftTop {
  background-color: #9ebaee;
}
.leftBottom {
  background-color: #744ef7;
}

.rightTop {
  background-color: #61467c;
}

.rightBottom {
  background-color: #489d87;
}

.bigImage {
  /* max-height: 100%; */
  height: 100%;
  /* width: 100%; */
  object-fit: contain;
}
.smallImage {
  /* max-width: 100%; */
  width: 100%;
  /* max-height: 100%; */

  height: 100%;
  /* position: absolute;
  left: 0; */
  object-fit: cover;
}

.myra {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.leftTop,
.rightTop {
  margin-bottom: 20px;
}

@media (max-width: 1280px) {
  .intro {
    margin-top: 200px;
  }
  .introWrapper,
  .leftRightWrapper {
    width: 95%;
  }
  .hello {
    font-size: 20px;
  }
  .craft,
  .experience {
    font-size: 50px;
    line-height: 50px;
  }

  .description {
    line-height: 28px;
    font-size: 16px;
  }

  /* .right {
    height: 510px;
  } */

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 240px;
  }
}

@media (max-width: 1024px) {
  .hello {
    font-size: 18px;
  }
  .craft,
  .experience {
    font-size: 48px;
    line-height: 48px;
  }

  .description {
    line-height: 26px;
    font-size: 14px;
  }

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 215px;
  }
}

@media (max-width: 768px) {
  .intro {
    margin-top: 80px;
  }
  .patternImage {
    width: 95%;
  }
  .introWrapper,
  .leftRightWrapper {
    width: 85%;
    margin: auto;
  }
  .hello {
    font-size: 20px;
  }

  .craft,
  .experience {
    font-size: 50px;
    line-height: 50px;
  }

  .description {
    font-size: 17px;
    line-height: 30px;
  }

  .right {
    /* height: 340px; */
    margin: 0 auto;
    height: 100%;
  }

  .leftRightWrapper {
    flex-direction: column;
  }

  .left {
    margin-right: 0;
  }

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 280px;
    height: 600px;
  }

  /* .leftTop,
  .rightBottom {
    height: 230px;
    width: 280px;
  }

  .leftBottom,
  .rightTop {
    width: 280px;
  } */

  .myra {
    bottom: 0;
    max-height: 145%;
  }
}

@media (max-width: 650px) {
  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 200px;
    height: 500px;
  }
}

@media (max-width: 425px) {
  .intro {
    margin-top: 80px;
  }
  /* .introWrapper {
    width: 85%;
  } */

  .patternImage {
    width: 95%;
  }

  .leftRightWrapper {
    display: flex;
    flex-direction: column;
  }
  .left {
    margin: 0;
    width: 100%;
  }
  .description {
    width: 100%;
  }

  .craft,
  .experience {
    font-size: 44px;
    line-height: 44px;
  }

  .right {
    display: flex;
    /* flex-direction: column; */
  }

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 160px;
    height: 400px;
    padding: 5px;
  }
  .leftTop,
  .rightTop {
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 140px;
    height: 320px;
  }
}
