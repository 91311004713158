.trainWrapper {
  margin: auto;
  margin-top: 300px;
  color: white;
  width: 1200px;
}

p {
  margin: 0;
}

.extraMargin {
  margin-top: 50px;
}

.extraPadding {
  padding-top: 100px;
}

.trainIntro {
  text-align: center;
}

.lightBg {
  background-color: #0d0d0d;
}

.tapToSkip {
  font-family: "Montserrat";
  font-weight: 700;
  color: #f5a623;
  cursor: pointer;
  margin: 0;
  text-decoration: none;
}

.headingText {
  font-family: "Montserrat";
  font-size: 72px;
  font-weight: 900;
  line-height: 74px;
  margin-bottom: 50px;
  text-align: center;
}

.bodyTextTrain {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  width: 60%;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.6);
}

.bodyTextTrainLeft {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 60px;
  text-align: left;
}
.bodyTextTrainLeftBottomSections {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
  margin: auto;
  text-align: left;
  width: 60%;
}

.introImageTrain {
  width: 30%;
  margin-top: 50px;
  padding-bottom: 100px;
}

.sectionWrapperFullWidth {
  background-color: #0d0d0d;
  padding-bottom: 100px;
  padding-top: 100px;
  margin: auto;
}

.sectionWrapperFlex {
  display: flex;
  margin: 0 auto;
  width: 1200px;
}

.sectionWrapperCenter {
  margin: 0 auto;
  width: 1200px;
  text-align: center;
  padding-top: 100px;
}

.sectionWrapper {
  flex: 1;
  margin: 0;
}
.sectionWrapper1 {
  flex: 1;
  margin: 0;
}
.sectionWrapper2 {
  flex: 1;
  margin: 0;
}

.imageTrainCards {
  width: 80%;
  margin: auto;
  /* margin-top: 50px; */
}

.spanBold {
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
}

.imageTrainCardsFlow {
  width: 50%;
  margin-top: 50px;
  padding-bottom: 100px;
}
.imageTrains {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  padding-bottom: 100px;
}

.subHeader {
  font-size: 24px;
  font-weight: 700;
}

.highlight {
  font-size: 36px;
  line-height: 48px;
  width: 60%;
  margin: 0 auto;
  padding: 50px 0;

  color: rgba(255, 255, 255, 0.5);
}

.bodyTextTrainLeftBottomSectionsLast {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  width: 60%;
}

.sectionWrapperCenterLast {
  margin: 0 auto;
  width: 1200px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1280px) {
  .trainWrapper {
    width: 80%;
  }
  .sectionWrapperFlex {
    width: 80%;
  }
  .sectionWrapperCenter {
    width: 80%;
  }
  .sectionWrapperCenterLast {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .headingText {
    font-size: 56px;
    margin-bottom: 30px;
  }
  .bodyTextTrain {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
  }
  .introImageTrain {
    width: 80%;
    padding-bottom: 50px;
  }
  .sectionWrapperFullWidth {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .bodyTextTrainLeft {
    font-size: 20px;
    line-height: 32px;
    margin-right: 40px;
  }
  .sectionWrapper1 {
    flex: 1.5;
    margin: 0;
  }
  .sectionWrapper2 {
    flex: 1;
    margin: 0;
  }
  .imageTrainCards {
    width: 100%;
    margin-top: 0;
  }
  .imageTrains {
    width: 100%;
    padding-bottom: 50px;
  }
  .bodyTextTrainLeftBottomSections {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
  }
  .highlight {
    font-size: 28px;
    line-height: 40px;
    padding-bottom: 0;
    width: 80%;
  }
  .imageTrainCardsFlow {
    padding-bottom: 50px;
    width: 80%;
  }
  .sectionWrapperCenter {
    padding-top: 50px;
  }
  .bodyTextTrainLeftBottomSectionsLast {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
    padding-bottom: 50px;
  }
  .sectionWrapperCenterLast {
    padding-top: 50px;
  }
}

@media (max-width: 650px) {
  .sectionWrapperFlex {
    flex-direction: column;
  }
  .bodyTextTrainLeft {
    margin-right: 0;
    margin-bottom: 50px;
  }
  .headingText {
    font-size: 36px;
    line-height: 48px;
  }
  .trainWrapper {
    margin-top: 200px;
  }
}

@media (max-width: 425px) {
  .bodyTextTrain {
    width: 100%;
  }
  .introImageTrain {
    width: 100%;
  }
  .imageTrainCardsFlow {
    width: 100%;
  }
  .subHeader {
    font-size: 20px;
    margin-top: 50px;
  }
  .bodyTextTrainLeft {
    margin-bottom: 0;
  }
  .imageTrainCards {
    margin-top: 50px;
  }
  .bodyTextTrainLeftBottomSections {
    width: 100%;
  }
  .highlight {
    width: 100%;
  }
  .bodyTextTrainLeftBottomSectionsLast {
    width: 100%;
  }
}
