@import url(https://fonts.googleapis.com/css?family=Montserrat:400,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,900&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: Lato, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    "Lato", monospace;
}

.App {
  text-align: center;
  font-family: "Montserrat";
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.item {
  width: inherit;
  height: inherit;
  background: white;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
}

.intro {
  margin-top: 250px;
  margin-bottom: 10px;
  width: 100vw;
  display: flex;
}

.introWrapper {
  width: 1200px;
  /* background-color: cadetblue; */
  margin: auto;
  display: flex;
  position: relative;
  /* height: 600px; */
}

.leftRightWrapper {
  display: flex;
  margin: auto;
  width: 1200px;
}

.patternImage {
  position: absolute;
  top: -150px;
  z-index: -1;
  opacity: 0.8;
  /* margin-left: 8.5%; */
}

.right {
  display: flex;
  flex: 1 1;
  /* width: 50%; */
  height: 600px;
  margin: auto;
}

.left {
  text-align: left;
  flex: 1 1;
  /* width: 50%; */
  margin: auto;
  margin-right: 20px;
}
.hello {
  color: #f5a623;
  font-size: 22px;
  margin-bottom: 16px;
  font-family: "Montserrat";
  font-weight: 300;
}
.craft {
  font-size: 60px;
  /* font-family: "Montserrat-Black"; */
  /* font-family: "Montserrat"; */
  font-family: "Liu Jian Mao Cao";
  font-weight: 600;
  color: white;
  margin: 0;
}

.experience {
  font-size: 62px;
  font-family: "Liu Jian Mao Cao";
  /* font-family: "Montserrat"; */
  font-weight: 900;
  color: #f5a623;
  margin-top: 0;
  line-height: 60px;
}

.description {
  /* width: 490px; */
  /* font-family: "Montserrat-Regular"; */
  font-family: "Montserrat";
  font-weight: 300;
  color: white;
  margin: 32px 0;
  line-height: 30px;
  font-size: 18px;
}

.linkReachOut {
  text-decoration: none;
  color: #f5a623;
  font-weight: 800;
}

.leftImagesWrapper,
.rightImagesWrapper {
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  width: 280px;
  box-sizing: border-box;
  padding: 10px;
}

.leftTop,
.rightBottom,
.leftBottom,
.rightTop {
  width: 100%;
  background-color: #f5a623;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.leftTop,
.rightBottom {
  height: 65%;
}

.leftBottom,
.rightTop {
  height: 35%;
}

.introNavLink {
  width: 100%;
  height: 100%;
}

.introNavLink2 {
  /* width: 100%; */
  height: 100%;
}

.leftTop {
  background-color: #9ebaee;
}
.leftBottom {
  background-color: #744ef7;
}

.rightTop {
  background-color: #61467c;
}

.rightBottom {
  background-color: #489d87;
}

.bigImage {
  /* max-height: 100%; */
  height: 100%;
  /* width: 100%; */
  object-fit: contain;
}
.smallImage {
  /* max-width: 100%; */
  width: 100%;
  /* max-height: 100%; */

  height: 100%;
  /* position: absolute;
  left: 0; */
  object-fit: cover;
}

.myra {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.leftTop,
.rightTop {
  margin-bottom: 20px;
}

@media (max-width: 1280px) {
  .intro {
    margin-top: 200px;
  }
  .introWrapper,
  .leftRightWrapper {
    width: 95%;
  }
  .hello {
    font-size: 20px;
  }
  .craft,
  .experience {
    font-size: 50px;
    line-height: 50px;
  }

  .description {
    line-height: 28px;
    font-size: 16px;
  }

  /* .right {
    height: 510px;
  } */

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 240px;
  }
}

@media (max-width: 1024px) {
  .hello {
    font-size: 18px;
  }
  .craft,
  .experience {
    font-size: 48px;
    line-height: 48px;
  }

  .description {
    line-height: 26px;
    font-size: 14px;
  }

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 215px;
  }
}

@media (max-width: 768px) {
  .intro {
    margin-top: 80px;
  }
  .patternImage {
    width: 95%;
  }
  .introWrapper,
  .leftRightWrapper {
    width: 85%;
    margin: auto;
  }
  .hello {
    font-size: 20px;
  }

  .craft,
  .experience {
    font-size: 50px;
    line-height: 50px;
  }

  .description {
    font-size: 17px;
    line-height: 30px;
  }

  .right {
    /* height: 340px; */
    margin: 0 auto;
    height: 100%;
  }

  .leftRightWrapper {
    flex-direction: column;
  }

  .left {
    margin-right: 0;
  }

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 280px;
    height: 600px;
  }

  /* .leftTop,
  .rightBottom {
    height: 230px;
    width: 280px;
  }

  .leftBottom,
  .rightTop {
    width: 280px;
  } */

  .myra {
    bottom: 0;
    max-height: 145%;
  }
}

@media (max-width: 650px) {
  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 200px;
    height: 500px;
  }
}

@media (max-width: 425px) {
  .intro {
    margin-top: 80px;
  }
  /* .introWrapper {
    width: 85%;
  } */

  .patternImage {
    width: 95%;
  }

  .leftRightWrapper {
    display: flex;
    flex-direction: column;
  }
  .left {
    margin: 0;
    width: 100%;
  }
  .description {
    width: 100%;
  }

  .craft,
  .experience {
    font-size: 44px;
    line-height: 44px;
  }

  .right {
    display: flex;
    /* flex-direction: column; */
  }

  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 160px;
    height: 400px;
    padding: 5px;
  }
  .leftTop,
  .rightTop {
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .leftImagesWrapper,
  .rightImagesWrapper {
    width: 140px;
    height: 320px;
  }
}

.navbar {
  width: 100vw;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 16px 0 16px 0;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  z-index: 100;
  position: fixed;
  top: 0;
}
.navWrapper {
  width: 1200px;
  display: flex;
  margin: auto 20px;
}

.navLinkLogo {
  text-decoration: none;
  color: #fff;
  display: flex;
}

.navLinkItems {
  text-decoration: none;
  color: #fff;
}

.logo {
  display: flex;
  text-align: left;
  height: 32px;
  margin: auto;
  cursor: pointer;
}

.logoText {
  /* font-family: "Montserrat-Regular"; */
  font-family: "Montserrat";
  margin-left: 10px;
}

.navLinks {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  text-align: left;
  font-size: 14px;
}

.linksWrapper {
  /* width: 100%; */
  display: flex;
}

.hamburger {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  margin: auto 40px;
}

.hamburgerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.line1,
.line2,
.line3 {
  width: 20px;
  height: 2px;
  background-color: #f5a623;
  margin: 2px auto;
  border-radius: 4px;
}

.link1,
.link2 {
  margin-right: 60px;
  cursor: pointer;
  padding-top: 16px;
}

/* .link2 {
  margin-right: 40px;
  cursor: pointer;
  padding-top: 16px;
} */

.link3 {
  cursor: pointer;
  padding-top: 16px;
  color: #fff;
  text-decoration: none;
}

.reduceOpacity {
  opacity: 0.4;
}

.close {
  display: none;
}

.openLinks {
  display: contents;
}

.open {
  height: 100vh;
  width: 100vh;
  z-index: 100;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  text-align: right;
}

@media (max-width: 1280px) {
  .navbar {
    width: 100%;
  }
  .navWrapper {
    width: 85%;
    display: flex;
  }
}

@media (max-width: 900px) {
  .link1,
  .link2 {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .logoText {
    display: none;
  }

  .link1,
  .link2 {
    display: none;
  }

  .link3 {
    padding-top: 0;
  }

  .aboutMe {
    margin: auto;
    padding-top: 8px;
  }

  .aboutMeText {
    display: none;
  }
}

.gradient1 {
  background-image: linear-gradient(rgba(27, 27, 27, 0.5), black);
  height: 100px;
  margin-top: 100px;
  width: 100vw;
}

.workTitle {
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  font-size: 48px;
}

.workCards {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.allCards {
  margin: auto;
}

.SeeMore {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  /* color: #f5a623; */
  color: #fff;
  cursor: pointer;
  margin: 60px auto;
  width: 280px;
  padding: 20px;
  border: 2px solid #f5a623;
  border-radius: 8px;
}

@media (max-width: 1280px) {
  .gradient1 {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .gradient1 {
    margin-top: 40px;
    height: 40px;
  }
}

@media (max-width: 425px) {
  .SeeMore {
    font-size: 16px;
    width: 200px;
    margin: 20px auto;
    margin-bottom: 40px;
  }
}

.cardLeft {
  color: white;
  width: 1200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  display: flex;
  margin: 50px 0;
  overflow: hidden;
}

.readMoreLeft {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 24px;
  color: #f5a623;
  cursor: pointer;
  margin: 0;
}

.cardImageLeft {
  margin: 0 30px;
  flex: 1 1;
  position: relative;
}

.cardImageContentLeft {
  position: absolute;
  /* bottom: -4px; */
  top: 10px;
  left: 0;
  width: 100%;
}

.cardTextLeft {
  flex: 1 1;
  text-align: left;
  margin: auto;
  margin-right: 60px;
  /* margin-left: 60px; */
  padding: 60px 0;
}

.cardTitleLeft {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 48px;
  margin-bottom: 0;
  margin-top: 0;
}

.cardCompanyLeft {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 16px;
  padding: 5px 16px;
  border-radius: 50px;
  text-align: center;
  margin-top: 10px;
}

.cardDescriptionLeft {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
  margin-top: 40px;
}

.cardLeftNavLink {
  text-decoration: none;
}

@media (max-width: 1280px) {
  .cardLeft {
    width: 90%;
    margin: 50px auto;
  }
  .cardTitleLeft {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .cardTitleLeft {
    font-size: 28px;
  }
  .cardDescriptionLeft {
    font-size: 18px;
    line-height: 30px;
  }
  .readMoreLeft {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .cardLeft {
    flex-direction: column;
    height: 700px;
    /* width: 100vw; */
  }
  .cardImageLeft {
    overflow: hidden;
  }
  .cardImageContentLeft {
    position: relative;
    top: 10px;
    left: -5%;
    width: 110%;
  }
  .cardTextLeft {
    padding: 60px;
    padding-top: 40px;
    padding-right: 0;
    flex: 0 1;
  }
}

@media (max-width: 425px) {
}

.cardRight {
  color: white;
  width: 1200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  display: flex;
  margin: 50px 0;
  overflow: hidden;
}

.readMoreRight {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 24px;
  color: #f5a623;
  cursor: pointer;
  margin: 0;
}

.cardImageRight {
  margin: 0 30px;
  flex: 1 1;
  /* width: 100%; */
  font-family: "Montserrat";
  position: relative;
}

.cardImageContentRight {
  position: absolute;
  /* bottom: -4px; */
  top: 10px;
  left: 0;
  width: 100%;
}

.cardRightNavLink {
  text-decoration: none;
}

.cardTextRight {
  /* flex: 1;
  text-align: left;
  margin: auto;
  margin-left: 60px;
  width: 626px;
  padding: 60px 0; */

  flex: 1 1;
  text-align: left;
  margin: auto;
  margin-left: 60px;
  padding: 60px 0;
}

.cardTitleRight {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 48px;
  margin-bottom: 0;
  margin-top: 0;
}

.cardCompanyRight {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 16px;
  padding: 5px 16px;
  border-radius: 50px;
  text-align: center;
  margin-top: 10px;
}

.cardDescriptionRight {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
  margin-top: 40px;
}

@media (max-width: 1280px) {
  .cardRight {
    width: 90%;
    margin: 50px auto;
  }
  .cardTitleRight {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .cardTitleRight {
    font-size: 28px;
  }
  .cardDescriptionRight {
    font-size: 18px;
    line-height: 30px;
  }
  .readMoreRight {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .cardRight {
    flex-direction: column-reverse;
    height: 700px;
  }
  .cardImageRight {
    overflow: hidden;
  }
  .cardImageContentRight {
    position: relative;
    top: 10px;
    left: -5%;
    width: 110%;
  }
  .cardTextRight {
    padding: 60px;
    padding-top: 40px;
    padding-left: 0;
    flex: 0 1;
  }
}

@media (max-width: 425px) {
}

.gradient {
  background-image: linear-gradient(rgba(27, 27, 27, 0.5), black);
  height: 100px;
  margin-top: 50px;
  width: 100vw;
}

.workTitle {
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  font-size: 48px;
}

.wrapper {
  margin-top: 200px;
}

.allExperiments {
  width: 1200px;
  /* height: 1000px; */
  margin: 40px auto;
  /* background-color: antiquewhite; */
  color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.descriptionText {
  text-align: left;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
}
.descriptionTitle {
  text-align: left;
  margin-bottom: 10px;
  font-size: 24px;
}

.card {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.experimentImage {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 8px;
}

.experimentimage:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.SeeMoreExp {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  /* color: #f5a623; */
  color: #fff;
  cursor: pointer;
  margin: 60px auto;
  width: 280px;
  padding: 20px;
  border: 2px solid #f5a623;
  border-radius: 8px;
}

.navlinkStyle {
  text-decoration: none;
  color: white;
}

@media (max-width: 1280px) {
  .allExperiments {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-top: 100px;
  }
  .allExperiments {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 20px;
  }
  .gradient {
    margin-top: 0px;
    height: 40px;
  }
}

@media (max-width: 425px) {
  .SeeMoreExp {
    font-size: 16px;
    width: 200px;
    margin: 20px auto;
    margin-bottom: 40px;
  }

  /* .allExperiments {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 20px;
  } */
}

.resumeWrapper {
  margin: auto;
  margin-top: 150px;
  width: 1200px;
}

.resumeTop {
  display: flex;
  /* flex-direction: column; */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
}

.profileWrapper {
  height: 200px;
  width: 200px;
}

.profile {
  width: 100%;
}

.name {
  margin-top: 80px;
  margin-left: 40px;
}

.resumeBottom {
  margin-top: 60px;
}

.jobTitle {
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 40px;
}

.jobDesc {
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 24px;
}

.jobExperience {
  margin-top: 40px;
}
.jobIntro {
  line-height: 46px;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 40px;
  margin-bottom: 60px;
}
.jobSubtitle {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

.education {
  margin-top: 100px;
}

.projectIntro {
  font-weight: 700;
  color: white;
  margin-top: 20px;
}

#extraSpace {
  margin-top: 20px;
}

#extraSpace2 {
  margin-top: 40px;
}
.links {
  color: #f5a623;
  text-decoration: none;
  font-weight: 900;
}

#linkSpace {
  padding-left: 20px;
}

.skills {
  margin-top: 100px;
}

.volunteer {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media (max-width: 1280px) {
  .resumeWrapper {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .resumeTop {
    flex-direction: column;
  }
  .profileWrapper {
    height: 300px;
    width: 300px;
  }
  .resumeBottom {
    margin-top: 50px;
  }
  .name {
    margin-top: 50px;
    margin-left: 0;
  }

  .volunteer {
    margin-bottom: 50px;
  }

  .jobIntro {
    font-size: 24px;
    line-height: 42px;
  }

  .jobTitle {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0;
    margin-top: 40px;
  }
}

.footer {
  border-top: 0.5px solid rgba(255, 255, 255, 0.3);
}

.footerWrapper {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 0;
}

.container1 {
  flex: 1 1;
  padding: 100px 40px;
  color: white;
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 700;
  border-right: 0.5px solid rgba(255, 255, 255, 0.3);
}

.container2 {
  flex: 1 1;
  font-size: 16px;
  line-height: 28px;
  padding: 100px 40px;
  color: rgba(255, 255, 255, 0.4);
}

.social {
  color: #f5a623;
  text-decoration: none;
}

.contactMe {
  margin-top: 50px;
}

.thanks {
  color: #f5a623;
  font-weight: 900;
  text-decoration: none;
}

.help {
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1200px) {
  .footerWrapper {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .footerWrapper {
    flex-direction: column;
    width: 100%;
  }

  .container1 {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
    border-right: 0;
    padding: 20px 40px;
  }

  .contactMe {
    margin-top: 30px;
  }
  .container2 {
    padding: 20px 40px;
  }
}

.containerWrapper {
  margin: auto;
  margin-top: 200px;
  width: 1200px;
}

.description {
  line-height: 24px;
  font-size: 16px;
}

.linkExperiment {
  text-decoration: none;
  font-weight: 900;
  color: #f5a623;
}

.imageContainer {
  margin: 50px auto;
}

.projectImages {
  width: 100%;
}

.projectImageMyra {
  width: 360px;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: calc(50% - 180px);
}

@media (max-width: 1280px) {
  .containerWrapper {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .containerWrapper {
    margin-top: 100px;
  }

  .projectImageMyra {
    width: 280px;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-left: calc(50% - 140px);
  }
}

.trainWrapper {
  margin: auto;
  margin-top: 300px;
  color: white;
  width: 1200px;
}

p {
  margin: 0;
}

.extraMargin {
  margin-top: 50px;
}

.extraPadding {
  padding-top: 100px;
}

.trainIntro {
  text-align: center;
}

.lightBg {
  background-color: #0d0d0d;
}

.tapToSkip {
  font-family: "Montserrat";
  font-weight: 700;
  color: #f5a623;
  cursor: pointer;
  margin: 0;
  text-decoration: none;
}

.headingText {
  font-family: "Montserrat";
  font-size: 72px;
  font-weight: 900;
  line-height: 74px;
  margin-bottom: 50px;
  text-align: center;
}

.bodyTextTrain {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  width: 60%;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.6);
}

.bodyTextTrainLeft {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
  margin-right: 60px;
  text-align: left;
}
.bodyTextTrainLeftBottomSections {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
  margin: auto;
  text-align: left;
  width: 60%;
}

.introImageTrain {
  width: 30%;
  margin-top: 50px;
  padding-bottom: 100px;
}

.sectionWrapperFullWidth {
  background-color: #0d0d0d;
  padding-bottom: 100px;
  padding-top: 100px;
  margin: auto;
}

.sectionWrapperFlex {
  display: flex;
  margin: 0 auto;
  width: 1200px;
}

.sectionWrapperCenter {
  margin: 0 auto;
  width: 1200px;
  text-align: center;
  padding-top: 100px;
}

.sectionWrapper {
  flex: 1 1;
  margin: 0;
}
.sectionWrapper1 {
  flex: 1 1;
  margin: 0;
}
.sectionWrapper2 {
  flex: 1 1;
  margin: 0;
}

.imageTrainCards {
  width: 80%;
  margin: auto;
  /* margin-top: 50px; */
}

.spanBold {
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
}

.imageTrainCardsFlow {
  width: 50%;
  margin-top: 50px;
  padding-bottom: 100px;
}
.imageTrains {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  padding-bottom: 100px;
}

.subHeader {
  font-size: 24px;
  font-weight: 700;
}

.highlight {
  font-size: 36px;
  line-height: 48px;
  width: 60%;
  margin: 0 auto;
  padding: 50px 0;

  color: rgba(255, 255, 255, 0.5);
}

.bodyTextTrainLeftBottomSectionsLast {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  width: 60%;
}

.sectionWrapperCenterLast {
  margin: 0 auto;
  width: 1200px;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1280px) {
  .trainWrapper {
    width: 80%;
  }
  .sectionWrapperFlex {
    width: 80%;
  }
  .sectionWrapperCenter {
    width: 80%;
  }
  .sectionWrapperCenterLast {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .headingText {
    font-size: 56px;
    margin-bottom: 30px;
  }
  .bodyTextTrain {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
  }
  .introImageTrain {
    width: 80%;
    padding-bottom: 50px;
  }
  .sectionWrapperFullWidth {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .bodyTextTrainLeft {
    font-size: 20px;
    line-height: 32px;
    margin-right: 40px;
  }
  .sectionWrapper1 {
    flex: 1.5 1;
    margin: 0;
  }
  .sectionWrapper2 {
    flex: 1 1;
    margin: 0;
  }
  .imageTrainCards {
    width: 100%;
    margin-top: 0;
  }
  .imageTrains {
    width: 100%;
    padding-bottom: 50px;
  }
  .bodyTextTrainLeftBottomSections {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
  }
  .highlight {
    font-size: 28px;
    line-height: 40px;
    padding-bottom: 0;
    width: 80%;
  }
  .imageTrainCardsFlow {
    padding-bottom: 50px;
    width: 80%;
  }
  .sectionWrapperCenter {
    padding-top: 50px;
  }
  .bodyTextTrainLeftBottomSectionsLast {
    font-size: 20px;
    line-height: 32px;
    width: 80%;
    padding-bottom: 50px;
  }
  .sectionWrapperCenterLast {
    padding-top: 50px;
  }
}

@media (max-width: 650px) {
  .sectionWrapperFlex {
    flex-direction: column;
  }
  .bodyTextTrainLeft {
    margin-right: 0;
    margin-bottom: 50px;
  }
  .headingText {
    font-size: 36px;
    line-height: 48px;
  }
  .trainWrapper {
    margin-top: 200px;
  }
}

@media (max-width: 425px) {
  .bodyTextTrain {
    width: 100%;
  }
  .introImageTrain {
    width: 100%;
  }
  .imageTrainCardsFlow {
    width: 100%;
  }
  .subHeader {
    font-size: 20px;
    margin-top: 50px;
  }
  .bodyTextTrainLeft {
    margin-bottom: 0;
  }
  .imageTrainCards {
    margin-top: 50px;
  }
  .bodyTextTrainLeftBottomSections {
    width: 100%;
  }
  .highlight {
    width: 100%;
  }
  .bodyTextTrainLeftBottomSectionsLast {
    width: 100%;
  }
}

.backroundWrapper {
  width: 80%;
  margin: auto;
}

.headingTextBeforeAfter {
  font-family: "Montserrat";
  font-size: 72px;
  font-weight: 900;
  line-height: 74px;
  margin-bottom: 200px;
  text-align: center;
  margin-top: 200px;
}

.images {
  width: 100%;
}

.imageSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 40px;
  margin-bottom: 100px;
}

.subHeading {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .headingTextBeforeAfter {
    font-size: 56px;
    margin-bottom: 100px;
  }
  .subHeading {
    margin-bottom: 20px;
  }
  .imageWrapper {
    margin-bottom: 40px;
  }
}

@media (max-width: 650px) {
  .headingTextBeforeAfter {
    font-size: 36px;
    line-height: 48px;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .subHeading {
    font-size: 20px;
  }
  .imageSection {
    margin-bottom: 50px;
  }
}

@media (max-width: 450px) {
  .imageSection {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
  }
}

@media (max-width: 350px) {
  .imageSection {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .headingTextBeforeAfter {
    font-size: 32px;
    line-height: 44px;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  .subHeading {
    font-size: 16px;
  }
  .imageWrapper {
    margin-bottom: 20px;
  }
}

