.backroundWrapper {
  width: 80%;
  margin: auto;
}

.headingTextBeforeAfter {
  font-family: "Montserrat";
  font-size: 72px;
  font-weight: 900;
  line-height: 74px;
  margin-bottom: 200px;
  text-align: center;
  margin-top: 200px;
}

.images {
  width: 100%;
}

.imageSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 40px;
  margin-bottom: 100px;
}

.subHeading {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  .headingTextBeforeAfter {
    font-size: 56px;
    margin-bottom: 100px;
  }
  .subHeading {
    margin-bottom: 20px;
  }
  .imageWrapper {
    margin-bottom: 40px;
  }
}

@media (max-width: 650px) {
  .headingTextBeforeAfter {
    font-size: 36px;
    line-height: 48px;
    margin-top: 150px;
    margin-bottom: 50px;
  }
  .subHeading {
    font-size: 20px;
  }
  .imageSection {
    margin-bottom: 50px;
  }
}

@media (max-width: 450px) {
  .imageSection {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
  }
}

@media (max-width: 350px) {
  .imageSection {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .headingTextBeforeAfter {
    font-size: 32px;
    line-height: 44px;
    margin-top: 120px;
    margin-bottom: 50px;
  }
  .subHeading {
    font-size: 16px;
  }
  .imageWrapper {
    margin-bottom: 20px;
  }
}
