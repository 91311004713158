.resumeWrapper {
  margin: auto;
  margin-top: 150px;
  width: 1200px;
}

.resumeTop {
  display: flex;
  /* flex-direction: column; */
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 20px;
}

.profileWrapper {
  height: 200px;
  width: 200px;
}

.profile {
  width: 100%;
}

.name {
  margin-top: 80px;
  margin-left: 40px;
}

.resumeBottom {
  margin-top: 60px;
}

.jobTitle {
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 40px;
}

.jobDesc {
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 24px;
}

.jobExperience {
  margin-top: 40px;
}
.jobIntro {
  line-height: 46px;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 40px;
  margin-bottom: 60px;
}
.jobSubtitle {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

.education {
  margin-top: 100px;
}

.projectIntro {
  font-weight: 700;
  color: white;
  margin-top: 20px;
}

#extraSpace {
  margin-top: 20px;
}

#extraSpace2 {
  margin-top: 40px;
}
.links {
  color: #f5a623;
  text-decoration: none;
  font-weight: 900;
}

#linkSpace {
  padding-left: 20px;
}

.skills {
  margin-top: 100px;
}

.volunteer {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media (max-width: 1280px) {
  .resumeWrapper {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .resumeTop {
    flex-direction: column;
  }
  .profileWrapper {
    height: 300px;
    width: 300px;
  }
  .resumeBottom {
    margin-top: 50px;
  }
  .name {
    margin-top: 50px;
    margin-left: 0;
  }

  .volunteer {
    margin-bottom: 50px;
  }

  .jobIntro {
    font-size: 24px;
    line-height: 42px;
  }

  .jobTitle {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0;
    margin-top: 40px;
  }
}
