.gradient1 {
  background-image: linear-gradient(rgba(27, 27, 27, 0.5), black);
  height: 100px;
  margin-top: 100px;
  width: 100vw;
}

.workTitle {
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  font-size: 48px;
}

.workCards {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.allCards {
  margin: auto;
}

.SeeMore {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  /* color: #f5a623; */
  color: #fff;
  cursor: pointer;
  margin: 60px auto;
  width: 280px;
  padding: 20px;
  border: 2px solid #f5a623;
  border-radius: 8px;
}

@media (max-width: 1280px) {
  .gradient1 {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .gradient1 {
    margin-top: 40px;
    height: 40px;
  }
}

@media (max-width: 425px) {
  .SeeMore {
    font-size: 16px;
    width: 200px;
    margin: 20px auto;
    margin-bottom: 40px;
  }
}
