.gradient {
  background-image: linear-gradient(rgba(27, 27, 27, 0.5), black);
  height: 100px;
  margin-top: 50px;
  width: 100vw;
}

.workTitle {
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  font-size: 48px;
}

.wrapper {
  margin-top: 200px;
}

.allExperiments {
  width: 1200px;
  /* height: 1000px; */
  margin: 40px auto;
  /* background-color: antiquewhite; */
  color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.descriptionText {
  text-align: left;
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
}
.descriptionTitle {
  text-align: left;
  margin-bottom: 10px;
  font-size: 24px;
}

.card {
  transition: transform 0.3s;
}

.experimentImage {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 8px;
}

.experimentimage:hover {
  transform: translateY(-10px);
}

.SeeMoreExp {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  /* color: #f5a623; */
  color: #fff;
  cursor: pointer;
  margin: 60px auto;
  width: 280px;
  padding: 20px;
  border: 2px solid #f5a623;
  border-radius: 8px;
}

.navlinkStyle {
  text-decoration: none;
  color: white;
}

@media (max-width: 1280px) {
  .allExperiments {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-top: 100px;
  }
  .allExperiments {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 20px;
  }
  .gradient {
    margin-top: 0px;
    height: 40px;
  }
}

@media (max-width: 425px) {
  .SeeMoreExp {
    font-size: 16px;
    width: 200px;
    margin: 20px auto;
    margin-bottom: 40px;
  }

  /* .allExperiments {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 20px;
  } */
}
