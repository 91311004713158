.cardLeft {
  color: white;
  width: 1200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  display: flex;
  margin: 50px 0;
  overflow: hidden;
}

.readMoreLeft {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 24px;
  color: #f5a623;
  cursor: pointer;
  margin: 0;
}

.cardImageLeft {
  margin: 0 30px;
  flex: 1;
  position: relative;
}

.cardImageContentLeft {
  position: absolute;
  /* bottom: -4px; */
  top: 10px;
  left: 0;
  width: 100%;
}

.cardTextLeft {
  flex: 1;
  text-align: left;
  margin: auto;
  margin-right: 60px;
  /* margin-left: 60px; */
  padding: 60px 0;
}

.cardTitleLeft {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 48px;
  margin-bottom: 0;
  margin-top: 0;
}

.cardCompanyLeft {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 16px;
  padding: 5px 16px;
  border-radius: 50px;
  text-align: center;
  margin-top: 10px;
}

.cardDescriptionLeft {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
  margin-top: 40px;
}

.cardLeftNavLink {
  text-decoration: none;
}

@media (max-width: 1280px) {
  .cardLeft {
    width: 90%;
    margin: 50px auto;
  }
  .cardTitleLeft {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .cardTitleLeft {
    font-size: 28px;
  }
  .cardDescriptionLeft {
    font-size: 18px;
    line-height: 30px;
  }
  .readMoreLeft {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .cardLeft {
    flex-direction: column;
    height: 700px;
    /* width: 100vw; */
  }
  .cardImageLeft {
    overflow: hidden;
  }
  .cardImageContentLeft {
    position: relative;
    top: 10px;
    left: -5%;
    width: 110%;
  }
  .cardTextLeft {
    padding: 60px;
    padding-top: 40px;
    padding-right: 0;
    flex: 0;
  }
}

@media (max-width: 425px) {
}
