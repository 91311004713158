.cardRight {
  color: white;
  width: 1200px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  display: flex;
  margin: 50px 0;
  overflow: hidden;
}

.readMoreRight {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 24px;
  color: #f5a623;
  cursor: pointer;
  margin: 0;
}

.cardImageRight {
  margin: 0 30px;
  flex: 1;
  /* width: 100%; */
  font-family: "Montserrat";
  position: relative;
}

.cardImageContentRight {
  position: absolute;
  /* bottom: -4px; */
  top: 10px;
  left: 0;
  width: 100%;
}

.cardRightNavLink {
  text-decoration: none;
}

.cardTextRight {
  /* flex: 1;
  text-align: left;
  margin: auto;
  margin-left: 60px;
  width: 626px;
  padding: 60px 0; */

  flex: 1;
  text-align: left;
  margin: auto;
  margin-left: 60px;
  padding: 60px 0;
}

.cardTitleRight {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 48px;
  margin-bottom: 0;
  margin-top: 0;
}

.cardCompanyRight {
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 16px;
  padding: 5px 16px;
  border-radius: 50px;
  text-align: center;
  margin-top: 10px;
}

.cardDescriptionRight {
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
  margin-top: 40px;
}

@media (max-width: 1280px) {
  .cardRight {
    width: 90%;
    margin: 50px auto;
  }
  .cardTitleRight {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .cardTitleRight {
    font-size: 28px;
  }
  .cardDescriptionRight {
    font-size: 18px;
    line-height: 30px;
  }
  .readMoreRight {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .cardRight {
    flex-direction: column-reverse;
    height: 700px;
  }
  .cardImageRight {
    overflow: hidden;
  }
  .cardImageContentRight {
    position: relative;
    top: 10px;
    left: -5%;
    width: 110%;
  }
  .cardTextRight {
    padding: 60px;
    padding-top: 40px;
    padding-left: 0;
    flex: 0;
  }
}

@media (max-width: 425px) {
}
